const formatDate = {
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString)
      const options = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      }
      const formatter = new Intl.DateTimeFormat(this.$i18n.locale, options)
      return formatter.format(date)
    },
  },
}

export default formatDate
