<template>
  <div>
    <div v-if="isActiveAutoplay" class="borderlines mt-4">
      <b-borderline
        :color="$metadatas.colorAccent"
        height="4px"
        class="mb-3 mt-2"
      />
    </div>
    <div class="seeMore pt-3">
      <p class="grey--text">{{ catchPhrase }}</p>
      <div
        class="primary--text"
        @click="isUniverse ? goTo(firstTagId) : goTo()"
      >
        {{ $t('cp_wsuggestioncard_see_more') }}
        <v-icon color="primary" small>mdi-arrow-right</v-icon>
      </div>
    </div>
    <div class="scroll-container">
      <v-card
        v-for="(content, index) in contents"
        :key="index"
        flat
        class="grey lighten-3 fixed-size-card"
        @click="launchContent(content)"
      >
        <v-badge
          v-if="content.isSuggested"
          color="primary"
          class="suggested-chip"
        >
          <template #badge>
            <v-icon color="white">mdi-pin</v-icon>
          </template>
        </v-badge>
        <div>
          <v-img
            v-if="content.metadatas.thumbnail"
            :lazy-src="getCaption(content.metadatas.thumbnail).lazySrc"
            :src="getCaption(content.metadatas.thumbnail).cdnSrc"
            aspect-ratio="1"
            class="ma-2 img-card"
          ></v-img>
          <v-img
            v-else
            :lazy-src="getCaption(content.metadatas.cards[0]).lazySrc"
            :src="getCaption(content.metadatas.cards[0]).cdnSrc"
            aspect-ratio="1"
            class="ma-2 img-card"
          ></v-img>
          <p class="mx-2 font-weight-bold card-title">{{ content.name }}</p>
        </div>
        <div class="contentMedatas ma-2">
          <p
            class="contentMedatas-publishedAt grey--text text--darken-2 white my-auto"
          >
            {{ formatDate(content.publishedAt) }}
          </p>
          <div
            v-if="
              content.metadatas.audios && content.metadatas.audios.length != 0
            "
            class="contentMedatas-duration grey--text text--darken-2 d-flex"
          >
            <v-icon small class="mr-1">mdi-timer-outline</v-icon>
            <p class="my-auto">
              {{
                formatTime(
                  content.metadatas.audios.reduce(
                    (sum, audio) => sum + audio.metadatas.duration,
                    0
                  )
                )
              }}
            </p>
          </div>
          <div
            v-else-if="
              content.metadatas.videos && content.metadatas.videos.length != 0
            "
            class="caption grey--text d-flex"
          >
            <v-icon small class="mr-1">mdi-timer-outline</v-icon>
            <p class="my-auto">
              {{ formatTime(content.metadatas.videos[0].metadatas.duration) }}
            </p>
          </div>
        </div>
        <div v-if="isOther" class="mt-1 mx-3 d-block">
          <p class="tag-name white--text text-xs-center">
            {{ content.tagName }}
          </p>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import formatDate from '~/mixins/formatDate'
import formatTime from '~/mixins/formatTime'
import { getCaption } from '~/@mediam/spoke-js/utils/getters'

export default {
  mixins: [formatTime, formatDate],
  props: {
    contents: {
      type: Array,
      required: true,
    },
    catchPhrase: {
      type: String,
      required: true,
    },
    isUniverse: {
      type: Boolean,
      required: true,
    },
    isOther: {
      type: Boolean,
      required: false,
    },
    isActiveAutoplay: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    launchContent(content) {
      this.$emit('launchContent', content)
    },
    goTo() {
      this.$emit('goTo')
    },
    getCaption(meta) {
      return getCaption(meta)
    },
  },
}
</script>

<style scoped>
.borderlines {
  margin: 0 22px;
  text-align: center;
  .b-borderline {
    display: inline-block;
    width: 75px;
    &__line {
      border-radius: 10px;
    }
  }
}
.seeMore {
  display: flex;
  justify-content: space-between;
  font-family: 'rubik';
  font-size: 14px !important;
  font-weight: 500 !important;
  div {
    cursor: pointer;
    text-decoration: underline;
    text-transform: uppercase;
  }
}
.scroll-container {
  display: flex;
  overflow-x: auto;
  padding: 10px 0;
  .fixed-size-card {
    border-radius: 6px;
    min-width: 160px;
    max-width: 160px;
    margin: 0 3px;
    cursor: pointer;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .img-card {
      max-width: 140px !important;
      max-height: 140px;
      border-radius: 10px;
    }
    .card-title {
      font-family: 'rubik';
      font-size: 14px;
      font-weight: 500 !important;
    }
    p {
      text-align: start;
    }
    .suggested-chip {
      position: absolute;
      top: 15px;
      left: 5px;
      z-index: 2;
    }
    .contentMedatas {
      font-family: 'rubik';
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      .contentMedatas-publishedAt {
        font-size: 11px !important;
        font-weight: 400 !important;
        padding: 4px 8px 4px 8px;
        border-radius: 3px;
      }
      .contentMedatas-duration {
        font-size: 11px !important;
        font-weight: 400 !important;
      }
    }
    .tag-name {
      background-color: #939393;
      font-family: 'rubik';
      padding: 4px 8px 4px 8px;
      border-radius: 3px;
      font-size: 11px;
      text-transform: uppercase;
    }
  }
}
</style>
