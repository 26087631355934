const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '65379c420ce29a970ff35608',
    enableExperimentalBlocks: false,
    authMode: ['local'],
    authLocalOptions: {
      mode: 'otp',
    },
    nuxt: {
      head: {
        title: "Baraka",
        meta: [
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content:
              'width=device-width, initial-scale=1, minimal-ui, shrink-to-fit=no',
          },
          {
            hid: 'description',
            name: 'description',
            content: `Découvrez les podcasts du groupe AMI`,
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            hid: 'robots',
            name: 'robots',
            content: 'noindex,nofollow',
          },
          {
            hid: 'ogSiteName',
            name: 'og:site_name',
            content: "Baraka",
          },
          {
            hid: 'ogTitle',
            name: 'og:title',
            content: 'Mediameeting/Airzen',
          },
          {
            hid: 'ogDescription',
            name: 'og:description',
            content: `Découvrez les podcasts du groupe AMI`,
          },
          {
            hid: 'ogType',
            name: 'og:type',
            content: 'website',
          },
        ],
      },
    },
    /**
    authMode: ['providers'],
    authOAuthProviders: [
      [
        'WSSOKeycloak',
        [
          {
            realm: 'Mediameeting',
            clientId: 'quoi29-gsuite',
            buttonText: 'Connexion Mediameeting',
          },
          {
            realm: 'Airzen',
            clientId: 'quoi29',
            buttonText: 'Connexion Airzen',
          },
          {
            realm: 'Audioimpact',
            clientId: 'quoi29-gsuite',
            buttonText: 'Connexion Mediameeting',
          },
        ],
      ],
    ],
*/
    enableSplashScreen: 'wavy',
    showCardDescription: true,
    metadatas: {
      name: 'Baraka',
      colorPrimary: '#5900D2',
      colorAccent: '#A88AE8',
    },
    analytics: {
      matomo: '44',
    },
    useShell: [
      'bb-default',
      {
        view: {
          allowHeadingOnMobile: false,
        },
        mode: 'universes',
        universes: [
          {
            type: 'tag',
            tagId: '653928f99f1a4e438e8d545d',
            i18n: {
              fr: {
                title: 'Baraka, le IN',
                description:
                  'Retrouvez les podcasts et contenus sur les séminaires et webinaires dans le programme de formation Baraka',
              },
            },
            heading: {
              color: '#FC8300',
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              canRadio: false,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/CamouflageOrange.png')
                  : require('~/assets/images/CamouflageOrange.png')
              },
            },
            filters: {
              type: 'solo',
              soloTagId: '65429fa7eed4d8e296c2c0de',
            },
          },
          {
            type: 'tag',
            tagId: '653929050ce29a6bb8f369cd',
            i18n: {
              fr: {
                title: 'Baraka, le OFF',
                description:
                  'Retrouvez les podcasts, informations et contenus bonus au programme de formation Baraka',
              },
            },
            heading: {
              color: '#918E6D',
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              canRadio: false,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/Camouflagegris_kaki.png')
                  : require('~/assets/images/Camouflagegris_kaki.png')
              },
            },
            filters: {
              type: 'solo',
              soloTagId: '6542a00eeed4d8f8efc2c0f5',
            },
          },
        ],
      },
    ],
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
