import spoke, { $metadata } from './spoke'

class EventHelper {
  identify(id, data) {
    const email = $metadata(data, 'email', 'undefined')
    const created = new Date()
    // eslint-disable-next-line
    const last_login = new Date()

    const personae = {
      ...data,
      email,
      created,
      last_login,
    }

    if (id) {
      spoke.eventManager.emit('identify', {
        id,
        personae,
      })
    }
  }

  exception(exception) {
    const exc =
      exception && typeof exception.toString === 'function'
        ? exception.toString()
        : 'unknown reason'

    spoke.eventManager.emit('exception', {
      exDescription: exc,
      exFatal: false,
    })
    this.track('exception', exc)
  }

  register(...params) {
    spoke.eventManager.emit('register', params)
  }

  visit(payload) {
    spoke.eventManager.emit('visit', payload)
  }
}

export default new EventHelper()
