var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{class:{
    'w-app': true,
    'w-app--default': true,
    [`w-app--page--${_vm.$route.name}`]: true,
    'w-app--mobile': _vm.$voicer.isMobile,
    'w-app--desktop': _vm.$voicer.isDesktopOrTablet,
    'w-app--with-player': _vm.isPersistantVisible,
    'w-app--ios': _vm.$voicer.isIos,
    'w-app--standalone': _vm.$voicer.isStandalone,
    'w-app--not-standalone': _vm.$voicer.isStandalone === false,
    'w-app--ie': _vm.$voicer.isIE,
    'w-app--highlighted': _vm.hasHighlightedContent,
    'w-app--noscroll': _vm.noScrollbar,
  },style:(_vm.$voicer.getThemeConfigProperty('components.app.style')),attrs:{"dark":_vm.$voicer.isThemeDark()}},[_c('w-loader-overlay'),_vm._v(" "),_c('w-screen-loader'),_vm._v(" "),(_vm.isAuthenticated)?_c('w-navigation-drawer',{attrs:{"navigations":_vm.navigations},model:{value:(_vm.navigationDrawer),callback:function ($$v) {_vm.navigationDrawer=$$v},expression:"navigationDrawer"}}):_vm._e(),_vm._v(" "),_c('v-toolbar',_vm._b({style:(_vm.$voicer.getThemeConfigProperty('components.toolbar.style')),attrs:{"color":_vm.$voicer.getThemeConfigProperty('components.toolbar.color'),"height":_vm.$voicer.getThemeConfigProperty('components.toolbar.height'),"dark":_vm.$voicer.getThemeConfigProperty('components.toolbar.dark')}},'v-toolbar',_vm.$voicer.getThemeConfigProperty('components.toolbar.props'),false),[_c('v-container',{staticClass:"w-toolbar__desktop-container"},[_c('w-block',{attrs:{"name":"pre-toolbar-1"}}),_vm._v(" "),_c('w-logo',_vm._b({style:(_vm.$voicer.getThemeConfigProperty('components.toolbar-logo.style'))},'w-logo',
          _vm.$voicer.getThemeConfigProperty('components.toolbar-logo.props')
        ,false)),_vm._v(" "),_c('v-toolbar-title',_vm._b({style:(_vm.$voicer.getThemeConfigProperty('components.toolbar-title.style'))},'v-toolbar-title',
          _vm.$voicer.getThemeConfigProperty('components.toolbar-title.props')
        ,false),[_vm._v("\n        "+_vm._s(_vm.$metadatas.appName)+"\n      ")]),_vm._v(" "),_c('w-block',{attrs:{"name":"pre-toolbar-2"}}),_vm._v(" "),_c('div',{staticClass:"w-toolbar__desktop-container__separator hidden-xs-only"}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('w-block',{attrs:{"name":"post-toolbar-1"}}),_vm._v(" "),(_vm.$voicer.isMobile)?_c('v-btn',{attrs:{"flat":"","icon":"","to":"/search"}},[_c('v-icon',[_vm._v("mdi-magnify")])],1):_vm._e(),_vm._v(" "),(!_vm.$voicer.isMobile)?_c('v-btn',{staticClass:"search-title",attrs:{"flat":"","round":"","to":"/search"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-magnify")]),_vm._v("\n        "+_vm._s(_vm.$t('glob_home_search'))+"\n      ")],1):_vm._e(),_vm._v(" "),(_vm.isAuthenticated)?_c('v-btn',{attrs:{"flat":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleDrawer.apply(null, arguments)}}},[_c('v-icon',[_vm._v(" mdi-account-box-outline ")])],1):_vm._e(),_vm._v(" "),_c('w-block',{attrs:{"name":"post-toolbar-2"}})],1)],1),_vm._v(" "),_c('v-content',[_c('w-block',{attrs:{"name":"pre-heading"}}),_vm._v(" "),_c('portal-target',{attrs:{"name":"before-container"}}),_vm._v(" "),_c('nuxt',{key:_vm.$route.fullPath}),_vm._v(" "),_c('w-block',{attrs:{"name":"after-container"}}),_vm._v(" "),_c('portal-target',{attrs:{"name":"after-container"}}),_vm._v(" "),_c('w-alert'),_vm._v(" "),(_vm.isAuthenticated)?_c('w-player-persistent'):_vm._e()],1),_vm._v(" "),_c('w-ath-safari'),_vm._v(" "),(_vm.$voicer.hasMatomoEnabled)?_c('w-rgpd-modal'):_vm._e(),_vm._v(" "),(_vm.$voicer.getConfig('enableSplashScreen'))?_c('w-splash-screen'):_vm._e(),_vm._v(" "),(
      _vm.$spoke.mediaplayer.content &&
      (_vm.$voicer.getConfig('hasSuggestedContent') ||
        (_vm.$voicer.getConfig('hasAutoplayContent') &&
          _vm.$store.state.player.autoplay))
    )?_c('w-suggestion-modal',{model:{value:(_vm.isSuggestionVisible),callback:function ($$v) {_vm.isSuggestionVisible=$$v},expression:"isSuggestionVisible"}}):_vm._e(),_vm._v(" "),_c('w-update-webapp'),_vm._v(" "),_c('portal-target',{attrs:{"name":"after-all"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }