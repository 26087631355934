var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"b-label pl-2 pr-2 pt-1 pb-1 text-alternate",class:{
    'font-weight-bold': _vm.bold,
    'b-label--small': _vm.small,
    'b-label--x-small': _vm.xSmall,
    [_vm.color]: true,
    [_vm.textColor]: true,
  },attrs:{"elevation":_vm.flat ? 0 : 1}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }