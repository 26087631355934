var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{class:{
    'bb-block-radio-heading': true,
    'pt-2': true,
    'bb-block-radio-heading--mobile': _vm.$voicer.isMobile,
  },attrs:{"color":_vm.$voicer.getShellConfig('featuredSectionColor')}},[_c('v-container',{staticClass:"pa-0 mb-0 mt-2 white"},[_c('v-sheet',{staticClass:"pa-2",style:({
        backgroundImage: `url(${require('../assets/megaphone.png')})`,
      }),attrs:{"color":"white"}},[_c('div',{staticClass:"block-text"},[_c('div',{staticClass:"title red--text mb-1"},[_vm._v("Lâche ta dédicace !")]),_vm._v(" "),_c('small',{staticClass:"grey--text text--darken-3"},[_vm._v("Et passe à la radio")])]),_vm._v(" "),_c('div',{staticClass:"block-btns"},[_c('v-btn',{attrs:{"fab":"","color":"primary","href":`${_vm.$voicer.getShellConfig('radioHeading.mail')}`}},[_c('v-icon',[_vm._v("mdi-email-outline")])],1),_vm._v(" "),_c('v-btn',{attrs:{"fab":"","color":"primary","href":`${_vm.$voicer.getShellConfig('radioHeading.phone')}`}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("phone")])],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }