var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"w-layout-modal",class:{
    'pa-0': _vm.fullsize || _vm.$vuetify.breakpoint.xsAndDown,
    'w-layout-modal--fullsize': _vm.fullsize || _vm.$vuetify.breakpoint.xsAndDown,
    'w-layout-modal--mobile': _vm.$vuetify.breakpoint.xsAndDown,
    'w-layout-modal--theme--dark': _vm.$voicer.isDarkTheme,
    'w-layout-modal--theme--light': _vm.$voicer.isLightTheme,
  },attrs:{"fill-height":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":"","justify-center":""}},[_c('v-flex',_vm._b({staticClass:"w-layout-modal-flex"},'v-flex',_vm.sizes,false),[(_vm.$slots['heading'] && _vm.noHeading === false)?_c('div',{staticClass:"w-layout-modal__heading"},[_vm._t("heading")],2):(_vm.noHeading === false)?_c('div',{staticClass:"w-layout-modal__heading pb-3"},[_c('w-logo',{attrs:{"size":"24"}}),_vm._v(" "),_c('h1',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.headTitle)}})],1):_vm._e(),_vm._v(" "),_c('v-card',_vm._b({staticClass:"w-layout-modal__card"},'v-card',_vm.props,false),[(_vm.$slots.title && _vm.noTitle === false)?_c('v-card-title',{staticClass:"pl-0 pr-0 pb-3"},[_vm._t("title")],2):_vm._e(),_vm._v(" "),(_vm.$slots.title && _vm.noTitle === false)?_c('v-divider'):_vm._e(),_vm._v(" "),_vm._t("before-content"),_vm._v(" "),(_vm.$slots.content)?_c('v-card-text',{staticClass:"grow w-layout-modal__content pa-0"},[_vm._t("content")],2):_vm._e(),_vm._v(" "),(_vm.$slots.actions)?_c('v-card-actions',{staticClass:"w-layout-modal__actions pa-0 mt-4"},[_vm._t("actions")],2):_vm._e(),_vm._v(" "),_vm._t("after-content")],2),_vm._v(" "),_c('div',{staticClass:"w-layout-modal__footer"},[_c('b-footer',{attrs:{"no-padding":""}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }