import $get from 'lodash.get'
import $merge from 'lodash.merge'
import localstore from 'store'

import {
  SET_COMPONENT_BOTTOM_NAV,
  SET_INITIAL_ROUTE,
  SET_CONTROLLED_ATH_IOS,
  SET_LAST_VISITED_ROUTE,
  SET_PAGE,
  SET_METRICS_STATE,
  SET_RGPD_BOX,
  SET_SITE,
  SET_SPLASHSCREEN_VISIBILITY,
  SET_SPLASHSCREEN_DEJA_VU,
  SET_SPOKE_CONFIGURED,
  SET_SPOKE_DEAD,
  SET_UPDATE_WEBAPP_DIALOG_VISIBLE,
} from './types'
import { getConfig, runExcentricFile } from '~/utils/voicer'

const excentrics = runExcentricFile('store', 'root')

const INITIAL_STATE = () => {
  const analyticsConfig = getConfig('analytics', {})
  const acceptedRGPD = localstore.get('acceptedRGPD', false)
  const refusedRGPD = localstore.get('refusedRGPD', false)
  let controlledRGPD = acceptedRGPD || refusedRGPD
  let optEnableMetricsTrackers = localstore.get(
    'acceptedRGPD',
    !localstore.get('refusedRGPD', navigator.doNotTrack === '1')
  )

  // site does not have any tracker needing rgpd control
  controlledRGPD = true
  optEnableMetricsTrackers = !!analyticsConfig.matomo
  if (navigator.doNotTrack === '1') {
    // implicit privacy trackers rejection
    controlledRGPD = true
    optEnableMetricsTrackers = false

    // unless rgpd is explicitely enabled by user
    // on /settings screen
    if (acceptedRGPD) {
      optEnableMetricsTrackers = true
    }
  }

  const controlledAthIOS = localstore.get('controlledAthIOS', false)

  return {
    /**
     * @default null
     * @type {String}
     */
    currentPage: 'default',

    /**
     * @default Object
     * @type {Object}
     * a server middleware (or client in spa mode)
     * will push current location each time route change
     * @see nuxt.config.js router middleware
     */
    lastVisitedRoutes: [],

    /**
     * @default {Undefined}
     * @type {Object}
     * a route object set before login redirection (see protection middleware)
     */
    initialRouteBeforeRedirect: undefined,

    /**
     * @default false
     * @type {Boolean}
     * true when spoke has sent a `configured` event
     */
    isSpokeConfigured: false,

    /**
     * @default false
     * @type {Boolean}
     * true if spoke send a `configuration_error` event
     */
    isSpokeDead: false,

    /**
     * @default
     * defined in config
     * @type {Boolean}
     * true while splashscreen is visible
     */
    isVisibleSplashScreen: !!getConfig('enableSplashScreen'),

    /**
     * @default false
     * true if splashscreen has been seen one time
     */
    dejaVuSplashScreen: false,

    /**
     * @default false
     * true if a new version is available (event fetched from sw)
     */
    updateWebappDialog: false,

    /**
     * @type {Object}
     * default object
     */
    site: {
      id: '',
      active: true,
      optAuthentication: true,
      optRegistrable: true,
      optTemplate: 'custom',
      name: 'bubblecast',
      metadatas: {
        name: 'Bubblecast',
      },
    },

    /**
     * @default true
     * @type {Boolean}
     * true will show the WBottomNav component
     */
    hasComponentBottomNav: false,

    /**
     * @type {Boolean}
     * If `true`, ATH notice already closed
     */
    controlledAthIOS,

    /**
     * @type {Boolean}
     * if false, RGPD box will be rendered (see definition above)
     */
    controlledRGPD,

    /**
     * @default true
     * @type {Boolean}
     * if false, GA, Mp are disabled
     */
    optEnableMetricsTrackers,
  }
}

export const state = () => ({
  ...$merge(INITIAL_STATE(), excentrics.state),
})

export const actions = $merge(
  {
    routerGoBack(context) {
      const { name: currentRouteName } = this.app.context.route
      const lastVisitedRoutes = context.state.lastVisitedRoutes
      const lastVisitedRoute =
        context.state.lastVisitedRoutes[lastVisitedRoutes.length - 2]

      // todo: possible minor issue on lastVisitedRoute.name === currentRouteName
      if (!lastVisitedRoute || lastVisitedRoute.name === currentRouteName) {
        this.app.router.push('/')
      } else {
        this.app.router.push(lastVisitedRoute)
      }
    },

    /**
     * set the controlled ath ios mutex to true
     */
    setControlledAthIOS({ commit }) {
      localstore.set('controlledAthIOS', true)
      commit(SET_CONTROLLED_ATH_IOS, true)
    },

    /**
     * set the current page
     * changing the page here will have some impacts
     * on the loaded contents (see contents module in the store)
     * @param {String} page a page identifier
     * @default default
     */
    setCurrentPage({ commit }, page = 'default') {
      commit(SET_PAGE, page)
    },

    /**
     * set the the value to `value` for the given `prop`
     * @param {string} prop
     * @param {boolean} [value=true]
     */
    setComponentState({ commit }, { prop, value = true }) {
      switch (prop) {
        case 'hasComponentBottomNav':
          return commit(SET_COMPONENT_BOTTOM_NAV, value)
        default:
          throw new Error(`Unknown property ${prop}`)
      }
    },

    /**
     * set initial route property
     * @param {object} route
     */
    setInitialRouteBeforeRedirect({ commit }, route) {
      commit(SET_INITIAL_ROUTE, route)
    },

    /**
     * set last visited route properties
     * @param {object} props
     */
    setLastVisitedRoute({ commit }, props) {
      commit(SET_LAST_VISITED_ROUTE, props)
    },

    /**
     * set splashscreen deja vu
     * @param {boolean}
     */
    setSplashScreenDejaVu({ commit }, value) {
      commit(SET_SPLASHSCREEN_DEJA_VU, value)
    },

    /**
     * set splashscreen visibility
     * @param {boolean}
     */
    setSplashScreenVisibility({ commit }, value) {
      commit(SET_SPLASHSCREEN_VISIBILITY, value)
    },

    /**
     * set splashscreen deja vu
     * @param {boolean}
     */
    setUpdateWebappDialogVisibility({ commit }, value) {
      commit(SET_UPDATE_WEBAPP_DIALOG_VISIBLE, value)
    },

    /**
     * update privacy settings
     * if false, metrics trackers are disabled for the current client
     */
    setControlledRGPD({ commit }, status) {
      commit(SET_RGPD_BOX, status)
    },

    enableMetricsTrackers({ commit, dispatch }) {
      localstore.remove('refusedRGPD')
      localstore.set('acceptedRGPD', true)
      commit(SET_METRICS_STATE, true)
      dispatch('setControlledRGPD', true)
      this.app.$tracking.enableMetricTrackers()
    },

    disableMetricsTrackers({ commit, dispatch }) {
      localstore.remove('acceptedRGPD')
      localstore.set('refusedRGPD', true)
      commit(SET_METRICS_STATE, false)
      dispatch('setControlledRGPD', true)
      this.app.$tracking.disableMetricTrackers()
    },
  },
  excentrics.actions
)

export const mutations = $merge(
  {
    [SET_COMPONENT_BOTTOM_NAV]: (state, value = true) => {
      state.hasComponentBottomNav = value
    },
    [SET_INITIAL_ROUTE]: (state, route) => {
      state.initialRouteBeforeRedirect = route
    },
    [SET_LAST_VISITED_ROUTE]: (state, params) => {
      const [...lastVisitedRoutes] = [...state.lastVisitedRoutes]

      lastVisitedRoutes.push(params)

      // max router store history limited to 10 entry
      if (lastVisitedRoutes.length > 10) {
        lastVisitedRoutes.shift()
      }
      state.lastVisitedRoutes = lastVisitedRoutes
    },
    [SET_CONTROLLED_ATH_IOS]: (state, value = true) => {
      state.controlledAthIOS = value
    },
    [SET_SPLASHSCREEN_DEJA_VU]: (state, value = true) => {
      state.dejaVuSplashScreen = value
    },
    [SET_SPLASHSCREEN_VISIBILITY]: (state, value = true) => {
      state.isVisibleSplashScreen = value
    },
    [SET_SPOKE_CONFIGURED]: (state, value = true) => {
      state.isSpokeConfigured = value
    },
    [SET_SPOKE_DEAD]: (state, value = true) => {
      state.isSpokeDead = value
    },
    [SET_UPDATE_WEBAPP_DIALOG_VISIBLE]: (state, value) => {
      state.updateWebappDialog = value
    },
    [SET_PAGE]: (state, page = 'default') => {
      if (!page || page.length === 0) {
        state.currentPage = 'default'
      } else {
        state.currentPage = page
      }
    },
    [SET_SITE]: (state, site) => {
      state.site = site
    },
    [SET_RGPD_BOX]: (state, value) => {
      state.controlledRGPD = value
    },
    [SET_METRICS_STATE]: (state, value) => {
      state.optEnableMetricsTrackers = value
    },
  },
  excentrics.mutations
)

export const getters = $merge(
  {
    lastVisitedRoute(state) {
      if (state.lastVisitedRoutes.length < 2) {
        return {
          name: 'home',
          path: '/',
          fullPath: '/?utm_source=nosrc',
          params: {},
          query: {},
          hash: '',
        }
      }
      return state.lastVisitedRoutes[state.lastVisitedRoutes.length - 2]
    },
    currentPage(state) {
      return state.currentPage
    },
    siteID(state) {
      return $get(state.site, 'id', '')
    },
    siteName(state) {
      return $get(state.site, 'metadatas.name', state.site.name)
    },
  },
  excentrics.getters
)
