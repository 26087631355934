<template>
  <v-navigation-drawer
    v-model="visible"
    fixed
    floating
    right
    temporary
    hide-overlay
    touchless
    :style="$voicer.getThemeConfigProperty('components.aside.style')"
    v-bind="$voicer.getThemeConfigProperty('components.aside.props')"
  >
    <v-toolbar flat class="transparent">
      <v-list class="pa-0">
        <v-list-tile avatar>
          <v-list-tile-avatar tile>
            <w-logo />
          </v-list-tile-avatar>

          <v-list-tile-content>
            <v-list-tile-title v-text="$metadatas.name" />
            <v-list-tile-sub-title
              v-if="$metadatas.desc"
              v-text="$metadatas.desc"
            />
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-toolbar>
    <v-divider />
    <div v-if="showUsername">
      <v-toolbar flat class="transparent">
        <v-list class="pa-0">
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <!-- <img src="https://randomuser.me/api/portraits/men/85.jpg"> -->
              <v-avatar color="primary" size="40">{{ userChars }}</v-avatar>
            </v-list-tile-avatar>

            <v-list-tile-content>
              <v-list-tile-title class="w-navigation-username">{{
                name
              }}</v-list-tile-title>
              <v-list-tile-sub-title>{{ email }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-toolbar>
      <v-divider />
    </div>

    <v-list>
      <v-list-tile v-if="!isIos && isNotificationsBtnDisplay" class="mb-2">
        <v-btn
          :disabled="isNotificationDenied || isNotificationGranted"
          color="primary"
          class="white--text"
          @click="getNotification()"
        >
          <v-icon left small>mdi-bell</v-icon>
          {{ $t('glob_notification_subscribe') }}
        </v-btn>
        <v-tooltip
          v-if="isNotificationDenied || isNotificationGranted"
          bottom
          close-delay="5000"
        >
          <template #activator="{ on }">
            <v-icon small v-on="on">mdi-information</v-icon>
          </template>
          <span>
            {{
              isNotificationDenied
                ? $t('glob_notification_denied')
                : isNotificationGranted
                ? $t('glob_notification_granted')
                : ''
            }}
            <!-- lien vers le guide à ajouter plus tard -->
            <!-- <b-link to="/guide" minor>
              {{ $t('glob_notification_guide') }}
            </b-link> -->
          </span>
        </v-tooltip>
      </v-list-tile>
      <v-divider v-if="!isIos && isNotificationsBtnDisplay" />
      <v-list-group
        v-for="navigation in navs"
        :key="navigation.title"
        v-model="navigation.active"
        :prepend-icon="navigation.icon"
        :append-icon="navigation.items ? 'mdi-chevron-down' : ''"
        no-action
        @click="handleClickMenu(navigation)"
      >
        <v-list-tile slot="activator">
          <v-list-tile-content>
            <v-list-tile-title v-text="navigation.title" />
          </v-list-tile-content>
        </v-list-tile>

        <div v-if="navigation.items">
          <v-list-tile
            v-for="subNavigation in navigation.items"
            :key="subNavigation.title"
            @click="handleClickMenu(subNavigation)"
          >
            <v-list-tile-content>
              <v-list-tile-title v-text="subNavigation.title" />
            </v-list-tile-content>

            <v-list-tile-action>
              <v-icon>{{ subNavigation.action }}</v-icon>
            </v-list-tile-action>
          </v-list-tile>
        </div>
      </v-list-group>
    </v-list>
    <v-dialog v-model="dialogVersions" max-width="290">
      <v-card>
        <v-card-title class="headline">
          {{ $t('cp_wnavigationdrawer_index_softwareversion') }}
        </v-card-title>
        <v-card-text>
          {{ $t('cp_wnavigationdrawer_index_interfaceversion') }}
          {{ coreVersion }}<br />
          {{ $t('cp_wnavigationdrawer_index_spokeversion') }} {{ spokeVersion }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click="dialogVersions = false">
            {{ $t('glob_close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
import pkg from '~/package.json'
import spoke from '~/utils/spoke'
import notificationPlugin from '~/plugins/sw-notify'
import utilsMixin from '~/mixins/utils'

export default {
  mixins: [utilsMixin],
  props: {
    navigations: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVersions: false,
      disabledNotifyBtn: false,
      isIos: this.$voicer.isIos,
      isNotificationsBtnDisplay: this.$voicer.getConfig(
        'isNotificationsBtnDisplay'
      ),
      isNotificationDenied: Notification.permission === 'denied',
      isNotificationGranted: Notification.permission === 'granted',
    }
  },
  computed: {
    coreVersion() {
      return pkg.version
    },
    visible: {
      get() {
        return this.value
      },
      set(state) {
        this.$emit('input', state)
      },
    },
    navs() {
      return this.navigations.map((nav) => {
        if (nav.items && Array.isArray(nav.items)) {
          nav.items = nav.items.map((snav) => {
            return {
              ...snav,
              title:
                typeof snav.title === 'function'
                  ? snav.title(this)
                  : snav.title,
            }
          })
        }

        return {
          ...nav,
          title: typeof nav.title === 'function' ? nav.title(this) : nav.title,
        }
      })
    },
    spokeVersion() {
      return spoke.version
    },
    showUsername() {
      return (
        !this.$voicer.isOpenSite() &&
        this.$voicer.hasLocalAuthModeUniquePassword() === false
      )
    },
  },
  methods: {
    async getNotification() {
      if ('serviceWorker' in navigator) {
        const permission = await Notification.requestPermission()
        if (permission === 'granted') {
          this.isNotificationGranted = true
          // eslint-disable-next-line no-unused-vars
          const handleNotifications = notificationPlugin(this)
        } else if (permission === 'denied') {
          this.isNotificationDenied = true
        } else {
          this.isNotificationDenied = false
          this.isNotificationGranted = false
        }
      }
    },
    handleClickMenu(item) {
      if (!item.disableAutoClose) {
        this.visible = false
      }
      if (item.noAction) {
        return
      }

      if (typeof item.onClick === 'function') {
        item.onClick(this)
      }

      // default type is link
      if (!item.type || item.type === 'link') {
        this.$router.push({
          path: item.value,
        })
      }

      // external link
      if (item.type === 'external-link') {
        window.open(item.value, '_blank')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.w-navigation-username {
  width: 190px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
