import spoke from '~/utils/spoke'

export default (context) => {
  try {
    if ('serviceWorker' in navigator && Notification.permission === 'granted') {
      context.$fire.messagingReady()
      setTimeout(async () => {
        const token = await context.$fire.messaging.getToken()
        try {
          await spoke.http.put('/sessions', {
            metadatas: { fcm_token: token },
          })
        } catch (error) {
          console.warn(
            '[notice] Failed to update session with FCM token',
            error
          )
        }
        const worker = await navigator.serviceWorker.getRegistration(
          'firebase-cloud-messaging-push-scope'
        )
        worker.active.postMessage(
          JSON.stringify({
            sessionId: spoke.user.session.id,
            siteId: spoke.site.id,
          })
        )
      }, 1000)
    }
  } catch (err) {
    console.warn('[notice] serviceWorker is not accessible')
  }
}
