var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-volume-controller"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.setupVolume = true}}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v(" mdi-volume-medium ")])],1),_vm._v(" "),_c('v-dialog',{attrs:{"offset-y":"","width":"380","max-width":"100%"},model:{value:(_vm.setupVolume),callback:function ($$v) {_vm.setupVolume=$$v},expression:"setupVolume"}},[_c('v-card',{staticClass:"w-volume-controller__popover"},[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('cmp_cpersistentplayer_wvolumecontroller_popup_title')))]),_vm._v(" "),_c('div',{staticClass:"pa-5"},[_c('div',{staticClass:"w-volume-controller__popopover_btns"}),_vm._v(" "),_c('vue-slider',{staticClass:"w-volume-controller__slider",attrs:{"height":3,"duration":0.1,"tooltip-formatter":_vm.formatter,"dot-style":{
            backgroundColor: _vm.$metadatas.colorPrimary,
          },"dot-size":18,"rail-style":{
            backgroundColor: '#dedede',
          },"process-style":{
            backgroundColor: _vm.$metadatas.colorPrimary,
          },"step-style":{
            backgroundColor: _vm.$metadatas.colorPrimary,
          },"tooltip-style":{
            backgroundColor: _vm.$metadatas.colorPrimary,
          },"tooltip":"always"},model:{value:(_vm.volume),callback:function ($$v) {_vm.volume=$$v},expression:"volume"}})],1),_vm._v(" "),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){$event.stopPropagation();_vm.setupVolume = false}}},[_vm._v(_vm._s(_vm.$t('glob_close')))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }