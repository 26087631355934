const $get = require('lodash.get')
const path = require('path')

const { getUniverseFromId } = require('./utils/universes')

const VALID_MODES = ['list', 'solo', 'universes']

const getIndexFromMode = (shellConfiguration) => {
  return {
    name: 'index',
    path: '/',
    component: path.resolve(
      __dirname,
      `./index-${shellConfiguration.mode}.vue`
    ),
    chunkName: 'pages/index',
  }
}

const validateConfiguration = (config) => {
  if (VALID_MODES.includes(config.mode) === false) {
    throw new Error(
      `invalid mode ${config.mode}, availables : ${VALID_MODES.join(', ')}`
    )
  }
}

module.exports = {
  name: 'bb-default',
  version: '1.1',
  // routes: ['listen-content', 'listen-content-comments'],
  config: {
    store() {
      return {
        auth: {
          ...require('./store/auth/index').default,
        },
        contents: {
          ...require('./store/contents/index').default,
        },
        player: {
          ...require('./store/player/index').default,
        },
        root: {
          ...require('./store/root/index').default,
        },
      }
    },
  },
  defaultShellConfiguration: {
    mode: 'universes', // list, universes or solo
    contentId: null, // required if mode = 'solo',
    alternativeContents: [],
    universes: [],
    options: {
      fetchCollections: false,
    },
    view: {
      allowHeadingOnMobile: true,
      featuredContents: true,
      displayPublisher: true,
    },
    additionalRoutes: []
  },
  configureBubblecast(config, shellConfiguration) {
    validateConfiguration(shellConfiguration)

    config.disabledComponents = [...config.disabledComponents, 'wToolbarSearch']
    // page listen is disabled in `solo` mode
    config.disablePageListen = shellConfiguration.mode === 'solo'
    config.dynamicLoadedPersistentPlayer =
      '@/excentrics/shells/bb-default/components/CPersistentPlayer/index.vue'

    config.routes.store['contents/getContents'] = []
    config.routes.store['contents/getContent'] = [
      'listen-content',
      'listen-content-comments',
    ]
    config.routes.store['filters/getCategories'] = [
      'index',
      'universe',
      'listen-content'
    ]

    config.pages = (pager) => {
      // must be append at the end of the storeActions array
      const COMMONS_STORE_ACTIONS = ['root/commonLogic']

      function getStoreActionsForIndex(mode) {
        const ACTIONS_ON_SOLO = [
          'contents/getSpecificContent',
          'contents/getAlternativeContents',
          ...COMMONS_STORE_ACTIONS,
        ]

        const ACTIONS_ON_LIST = [
          $get(shellConfiguration, 'view.featuredContents') === true && 'contents/getFeaturedContents',
          'contents/getListContents',
        ].filter(Boolean)

        // todo:
        // if `universes` config contains only `type:tag` items,
        // we don't need to call contents/getCollections, skipit
        // another logic must be implemented
        const ACTIONS_ON_UNIVERSES = [
          $get(shellConfiguration.options, 'fetchCollections') === true && 'contents/getCollections',
          $get(shellConfiguration, 'view.featuredContents') === true && 'contents/getFeaturedContents',
          'contents/getCollectionsContents',
          ...COMMONS_STORE_ACTIONS,
        ].filter(Boolean)

        if (mode === 'universes') {
          return ACTIONS_ON_UNIVERSES
        } else if (mode === 'solo') {
          return ACTIONS_ON_SOLO
        } else if (mode === 'list') {
          return ACTIONS_ON_LIST
        }

        return []
      }

      const pages = pager.update('index', {
        storeActions: getStoreActionsForIndex(shellConfiguration.mode),
      })

      if (shellConfiguration.mode === 'universes') {
        pages.add({
          name: 'universe',
          storeActions: [
            [
              'contents/getCollectionContentsList',
              (context) => {
                const id = context.route.params.universe
                const universe = getUniverseFromId(
                  context.store.getters['auth/collections'],
                  id
                )

                return {
                  [universe.type === 'tag' ? 'tagId' : 'collectionId']: id,
                  type: universe.type
                }
              },
            ],
          ],
          router: {
            path: '/universe/:universe',
          },
        })
      }

      return pages
    }

    // todo: merge with strategy
    config.additionalRoutes = [
      getIndexFromMode(shellConfiguration),
      {
        name: 'listen-content',
        path: '/listen/:content',
        component: path.resolve(__dirname, './listen-content.vue'),
        chunkName: 'pages/listen/_content',
      },
      {
        name: 'listen-content-comments',
        path: '/listen/:content/comments',
        component: path.resolve(__dirname, './listen-content-comments.vue'),
        chunkName: 'pages/listen/_content/comments',
      },
      {
        name: 'universe',
        path: '/universe/:universe',
        component: path.resolve(
          __dirname,
          './pages/universes/collection/index.vue'
        ),
        chunkName: 'pages/universe/_universe',
      },
      ...shellConfiguration.additionalRoutes
    ]
  },
  nuxt() {
    return {
      styleResources: {
        scss: [path.resolve(__dirname, './assets/main.scss')],
      },
    }
  },
}
