import eventHelper from '~/utils/event'

function installMTOTracker(token) {
  const _paq = (window._paq = window._paq || [])

  if (document.getElementById('bb-mto-tracker')) {
    return _paq
  }

  _paq.push(['trackPageView'])
  _paq.push(['enableLinkTracking'])

  const u = '//mto.mediameeting.tech/'
  _paq.push(['setTrackerUrl', u + 'matomo.php'])
  _paq.push(['setSiteId', token])
  const d = document
  const g = d.createElement('script')
  const s = d.getElementsByTagName('script')[0]

  g.type = 'text/javascript'
  g.async = true
  g.src = u + 'matomo.js'
  g.id = 'bb-mto-tracker'
  s.parentNode.insertBefore(g, s)

  return _paq
}

function removeMTOTracker() {
  if (document && document.getElementById('bb-mto-tracker')) {
    document.getElementById('bb-mto-tracker').outerHTML = ''
  }
}

export default (context, inject) => {
  const { app } = context

  const { optEnableMetricsTrackers } = context.store.state.root

  const mtoToken = app.$voicer.getConfig('analytics.matomo', false)

  class Tracking {
    // pseudo priv
    _mtoActive = optEnableMetricsTrackers && !!mtoToken

    // pseudo pub
    disabled = optEnableMetricsTrackers === false

    constructor() {
      if (this.mtoActive) {
        this.mt = installMTOTracker(mtoToken)
      }

      app.router.afterEach((to, from) => {
        eventHelper.visit({ from: from.fullPath, to: to.fullPath })
      })
    }

    get mtoActive() {
      return this._mtoActive
    }

    set mtoActive(status) {
      if (mtoToken === false) {
        return
      }
      if (status === false) {
        window._paq.push(['optUserOut'])
        removeMTOTracker()
      } else {
        installMTOTracker(mtoToken)
        window._paq.push(['forgetUserOptOut'])
      }
    }

    identifyUser() {
      if (app.$spoke.site.optAuthentication === false) {
        eventHelper.identify(
          app.$spoke.user.session.id,
          app.$spoke.user.session.toJSON()
        )
      } else {
        eventHelper.identify(app.$spoke.user.id, app.$spoke.user.toJSON())
      }
    }

    captureException(exception) {
      eventHelper.exception(exception)
    }

    disableMetricTrackers() {
      if (this.disabled === false) {
        this.disabled = true
        this.mtoActive = false
        app.$spoke.eventManager.emit('disable_metrics_trackers', {
          mto: false,
        })
      }
    }

    enableMetricTrackers() {
      if (this.disabled === true) {
        this.disabled = false
        this.mtoActive = true
        app.$spoke.eventManager.emit('enable_metrics_trackers', {
          mto: true,
        })
      }
    }
  }

  inject('tracking', new Tracking())
}
