import { render, staticRenderFns } from "./b-borderline.global.vue?vue&type=template&id=82fcc306"
import script from "./b-borderline.global.vue?vue&type=script&lang=js"
export * from "./b-borderline.global.vue?vue&type=script&lang=js"
import style0 from "./b-borderline.global.vue?vue&type=style&index=0&id=82fcc306&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VSheet})
