<template>
  <v-dialog
    v-model="isSuggestionVisible"
    :fullscreen="isMobile"
    max-width="560px"
    :transition="isMobile ? 'dialog-bottom-transition' : 'dialog-transition'"
    persistent
  >
    <v-card class="card pb-4 px-4">
      <v-toolbar flat color="white" class="toolbar">
        <v-btn icon class="btn-close text-sm-left" @click.stop="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-if="autoplayContent && isActiveAutoplay" class="card-text">
        <v-img
          v-if="autoplayContent.metadatas.thumbnail"
          :src="autoplayContent.metadatas.thumbnail.value"
          class="img mr-3"
          aspect-ratio="1"
        ></v-img>
        <v-img
          v-else
          :src="autoplayContent.metadatas.cards[0].value"
          class="img mr-3"
          aspect-ratio="1"
        ></v-img>
        <div>
          <v-card-text
            v-if="!isCanceled"
            class="card-pre-title font-italic pa-0"
          >
            {{ $t('cp_wsuggestionmodal_next_in') }} {{ timeLeft }}
          </v-card-text>
          <v-card-text class="card-title pa-0">
            {{ autoplayContent.name }}
          </v-card-text>
          <div class="subtitle mt-2">
            <v-card-text class="py-1 pl-0 caption text-uppercase text-no-wrap">
              {{ formatDate(autoplayContent.publishedAt) }}
            </v-card-text>
            <v-card-text
              v-if="autoplayContent.metadatas.audios"
              class="py-1 px-2 caption text-uppercase text-no-wrap grey lighten-3"
            >
              {{
                getHumanDuration(
                  autoplayContent.metadatas.audios.reduce(
                    (sum, audio) => sum + audio.metadatas.duration,
                    0
                  )
                )
              }}
            </v-card-text>
            <v-card-text
              v-else
              class="py-1 px-2 caption text-uppercase text-no-wrap grey lighten-3"
            >
              {{
                getHumanDuration(
                  autoplayContent.metadatas.videos.reduce(
                    (sum, video) => sum + video.metadatas.duration,
                    0
                  )
                )
              }}
            </v-card-text>
          </div>
        </div>
      </div>
      <v-card-actions
        v-if="autoplayContent && isActiveAutoplay"
        class="card-action px-0 mt-3 mb-2"
      >
        <v-btn
          color="grey lighten-2"
          :disabled="isCanceled"
          block
          depressed
          @click="closeModal('cancel')"
        >
          {{ $t('cp_wsuggestionmodal_cancel') }}
        </v-btn>
        <v-btn
          color="primary lighten-4"
          block
          depressed
          class="progress-btn"
          @click="launchContent(autoplayContent)"
        >
          <span>{{ $t('cp_wsuggestionmodal_listen') }}</span>

          <v-progress-linear
            :value="progress"
            color="primary"
            height="50"
          ></v-progress-linear>
        </v-btn>
      </v-card-actions>
      <w-suggestion-card
        v-if="isActiveSuggested"
        :catch-phrase="$t('cp_wsuggestionmodal_theme')"
        :contents="contents"
        :is-universe="isUniverse"
        :is-other="false"
        :is-active-autoplay="isActiveAutoplay"
        @goTo="isUniverse ? goTo(collection.id) : goTo()"
        @launchContent="launchContent($event)"
      />
      <w-suggestion-card
        v-if="isActiveSuggested && isUniverse && otherContents.length"
        :catch-phrase="$t('cp_wsuggestionmodal_other')"
        :contents="otherContents"
        :is-universe="isUniverse"
        :is-other="true"
        @launchContent="launchContent($event)"
        @goTo="goTo()"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import humanizeDuration from 'humanize-duration'
import { mapGetters } from 'vuex'
import spoke from '~/utils/spoke'
import formatDate from '~/mixins/formatDate'
import formatTime from '~/mixins/formatTime'
import WSuggestionCard from '~/components/WSuggestionCard'
import ContentModel from '~/@mediam/spoke-js/models/brocoli/Content'

export default {
  components: {
    WSuggestionCard,
  },
  mixins: [formatTime, formatDate],
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      progress: 0,
      interval: null,
      timeLeft: 0,
      contents: [],
      otherContents: [],
      autoplayContent: null,
      collection: null,
      suggestedContents: [],
      isUniverse: this.$voicer.isUniverse,
      isMobile: this.$voicer.isMobile,
      collections: this.$voicer.collections,
      collectionsIds: this.$voicer.collections
        ? this.$voicer.collections.map((collection) => collection.tagId)
        : [],
      currentTime: 0,
      duration: 0,
      intervalDuration: 10000,
      buffer: 5,
      intervalId: null,
      remainingTime: 0,
      isRecentClosed: false,
      isCanceled: false,
      isActiveSuggested: this.$voicer.getConfig('hasSuggestedContent'),
    }
  },
  computed: {
    isActiveAutoplay() {
      return JSON.parse(this.$store.state.player.autoplay)
    },
    isSuggestionVisible: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    ...mapGetters({
      tags: 'filters/categories',
    }),
  },
  watch: {
    isSuggestionVisible(value) {
      if (value) {
        if (this.progress === 0) {
          this.startProgress()
        }
      }
    },
    $route(to, from) {
      if (this.contents && this.contents.length) {
        this.fetchDatas()
      }
    },
    currentTime(newTime) {
      this.remainingTime = this.duration - newTime
      if (this.remainingTime <= this.buffer && this.isRecentClosed === false) {
        if (
          spoke.mediaplayer.episode.episodeIndex ===
          spoke.mediaplayer.episode.content.data.episodes.length - 1
        ) {
          this.isSuggestionVisible = true
        }
      }
    },
  },
  mounted() {
    if (spoke.mediaplayer.episode.content.data.type !== 'live') {
      this.intervalId = setInterval(this.checkAudioTime, 100)
    }
    this.fetchDatas()
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  methods: {
    async fetchDatas() {
      this.autoplayContent = null
      this.suggestedContents =
        spoke.mediaplayer.episode.content.data.suggestedContents
      if (this.isUniverse) {
        this.collection = this.getContentCollection()
        await Promise.all([
          this.getOldContents(this.collection),
          this.getOtherCollectionsContents(this.collection),
        ])
      } else {
        await this.getOldContents()
      }
    },
    checkAudioTime() {
      this.currentTime = spoke.mediaplayer.episode.currentTime
      this.duration = spoke.mediaplayer.episode.data.metadatas.duration
    },
    goTo(collectionId) {
      this.closeModal()
      if (collectionId) {
        this.$router.push({
          path: `/universe/${collectionId}`,
        })
      } else {
        this.$router.push({ path: '/' })
      }
    },
    closeModal(howToClose) {
      clearInterval(this.interval)
      if (howToClose === 'cancel') {
        this.isCanceled = true
        this.progress = this.intervalDuration
      } else {
        this.isCanceled = false
        this.progress = 0
        this.isRecentClosed = true
        setTimeout(() => {
          this.isRecentClosed = false
        }, 6000)
        this.isSuggestionVisible = false
      }
    },
    launchContent(content) {
      this.closeModal()
      this.$store.dispatch('player/changePlayerContent', {
        content: new ContentModel(content).data,
        noRedirect: false,
      })
      if (this.autoplayContent.type !== 'video' || content.type !== 'video') {
        this.$store.dispatch('player/ctrlPlayer', {
          action: 'play',
          content: new ContentModel(content).data,
        })
      }
    },
    startProgress() {
      const step = 100
      if (this.autoplayContent && this.isActiveAutoplay) {
        this.interval = setInterval(() => {
          this.progress += (step / this.intervalDuration) * 100
          this.timeLeft = Math.ceil(
            (this.intervalDuration -
              (this.progress / 100) * this.intervalDuration) /
              1000
          )
          if (this.progress >= 100) {
            clearInterval(this.interval)
            this.launchContent(this.autoplayContent)
          }
        }, step)
      }
    },
    getContentCollection() {
      const contentTagNames = spoke.mediaplayer.episode.content.data.tags
      let contentCollection
      this.tags.forEach((parentTag) => {
        const matchingTag = parentTag.tags.find((tag) => {
          return (
            contentTagNames.includes(tag.name) &&
            this.collectionsIds.includes(tag.id)
          )
        })
        if (matchingTag) {
          contentCollection = matchingTag
        }
      })
      return contentCollection
    },
    async getOldContents(tag) {
      this.contents = []
      const tmpSuggested = []
      if (this.suggestedContents && tag) {
        tmpSuggested.push(
          ...this.suggestedContents.filter((content) =>
            content.tags.includes(tag.name)
          )
        )
      } else if (this.suggestedContents) {
        tmpSuggested.push(...this.suggestedContents)
      }
      if (tmpSuggested.length) {
        this.autoplayContent = tmpSuggested[0]
        this.contents = tmpSuggested.map((content) => ({
          ...content,
          isSuggested: true,
        }))
      }
      const limit = 3 - tmpSuggested.length
      if (limit > 0) {
        const currentPublishedAt = new Date(
          spoke.mediaplayer.episode.content.data.publishedAt
        )
        currentPublishedAt.setSeconds(currentPublishedAt.getSeconds() - 10)
        const params = {
          limit,
          toDate: currentPublishedAt.toISOString(),
          is: 'podcast',
        }
        if (tag) {
          params.tags = [tag.id]
        }
        const response = await spoke.http.get(`/contents`, {
          params,
        })
        this.contents = this.contents.concat(response.items)

        if (!this.autoplayContent) {
          this.autoplayContent = this.contents[0]
        }
      }

      if (this.contents.length < 3) {
        this.getRecentContents(tag)
      }
    },
    async getRecentContents(tag) {
      const limit = 3 - this.contents.length
      const currentPublishedAt = new Date(
        spoke.mediaplayer.episode.content.data.publishedAt
      )
      currentPublishedAt.setSeconds(currentPublishedAt.getSeconds() + 10)
      if (limit > 0) {
        const params = {
          limit,
          fromDate: currentPublishedAt.toISOString(),
          is: 'podcast',
        }
        if (tag) {
          params.tags = [tag.id]
        }
        const response = await spoke.http.get(`/contents`, {
          params,
        })
        if (!this.autoplayContent && response.items.length) {
          this.autoplayContent = response.items[0]
        }
        this.contents = this.contents.concat(response.items)
      }
    },
    async getOtherCollectionsContents(contentCollection) {
      this.otherContents = []
      let suggestedCollectionIds = []
      if (this.suggestedContents) {
        this.suggestedContents.forEach((content) => {
          if (
            !contentCollection ||
            !content.tags.includes(contentCollection.name)
          ) {
            content.isSuggested = true
            content.tagName = content.tags.find((tag) =>
              this.collections.some(
                (collection) =>
                  collection.i18n[this.$voicer.locale].title === tag
              )
            )
            this.otherContents.push(content)
          }
          suggestedCollectionIds = suggestedCollectionIds.concat(content.tags)
        })
      }
      // collections non suggéré et pas celle du contenu actuel
      let otherCollections = []
      if (contentCollection) {
        otherCollections = this.collections
          .filter(
            (coll) =>
              coll.tagId !== contentCollection.id &&
              !suggestedCollectionIds.includes(coll.tagId)
          )
          .slice(0, 3 - this.otherContents.length)
      } else {
        otherCollections = this.collections.slice(
          0,
          3 - this.otherContents.length
        )
      }
      const promises = otherCollections.map(async (collection) => {
        const response = await spoke.http.get(`/contents`, {
          params: {
            limit: 1,
            is: 'podcast', // TYPES
            tags: [collection.tagId],
          },
        })
        return { items: response.items, collection }
      })
      const res = await Promise.all(promises)
      res.forEach(({ items, collection }) => {
        items.forEach((item) => {
          item.tagName = collection.i18n[this.$voicer.locale].title
          this.otherContents.push(item)
        })
      })
    },
    getHumanDuration(duration) {
      return humanizeDuration(duration * 1000, {
        round: true,
        language: `${this.$i18n.locale}`,
        units: ['h', 'm'],
      })
    },
  },
}
</script>

<style scoped>
.card {
  border-radius: 25px;
  .toolbar {
    display: flex;
    justify-content: right;
    height: 50px;
    .btn-close {
      display: block;
    }
    .v-toolbar__content {
      padding: 0;
    }
  }
  .card-text {
    font-family: 'rubik';
    display: flex;
    .img {
      min-width: 140px;
      max-width: 140px;
    }
    .card-pre-title {
      font-size: 14px;
      font-weight: 400;
    }
    .card-title {
      font-size: 14px;
      font-weight: 500;
    }
    .subtitle {
      display: inline-flex;
      font-size: 11px;
    }
  }
  .card-action {
    display: flex;
    justify-content: center;
    .progress-btn {
      position: relative;
      overflow: hidden;
      span {
        z-index: 2;
      }
      .v-progress-linear {
        width: 130%;
        position: absolute;
        z-index: 1;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .card {
    border-radius: 0;
    padding: 0 15px 0 15px !important;
  }
}
</style>
