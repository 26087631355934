var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isActiveAutoplay)?_c('div',{staticClass:"borderlines mt-4"},[_c('b-borderline',{staticClass:"mb-3 mt-2",attrs:{"color":_vm.$metadatas.colorAccent,"height":"4px"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"seeMore pt-3"},[_c('p',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.catchPhrase))]),_vm._v(" "),_c('div',{staticClass:"primary--text",on:{"click":function($event){_vm.isUniverse ? _vm.goTo(_vm.firstTagId) : _vm.goTo()}}},[_vm._v("\n      "+_vm._s(_vm.$t('cp_wsuggestioncard_see_more'))+"\n      "),_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-arrow-right")])],1)]),_vm._v(" "),_c('div',{staticClass:"scroll-container"},_vm._l((_vm.contents),function(content,index){return _c('v-card',{key:index,staticClass:"grey lighten-3 fixed-size-card",attrs:{"flat":""},on:{"click":function($event){return _vm.launchContent(content)}}},[(content.isSuggested)?_c('v-badge',{staticClass:"suggested-chip",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-pin")])]},proxy:true}],null,true)}):_vm._e(),_vm._v(" "),_c('div',[(content.metadatas.thumbnail)?_c('v-img',{staticClass:"ma-2 img-card",attrs:{"lazy-src":_vm.getCaption(content.metadatas.thumbnail).lazySrc,"src":_vm.getCaption(content.metadatas.thumbnail).cdnSrc,"aspect-ratio":"1"}}):_c('v-img',{staticClass:"ma-2 img-card",attrs:{"lazy-src":_vm.getCaption(content.metadatas.cards[0]).lazySrc,"src":_vm.getCaption(content.metadatas.cards[0]).cdnSrc,"aspect-ratio":"1"}}),_vm._v(" "),_c('p',{staticClass:"mx-2 font-weight-bold card-title"},[_vm._v(_vm._s(content.name))])],1),_vm._v(" "),_c('div',{staticClass:"contentMedatas ma-2"},[_c('p',{staticClass:"contentMedatas-publishedAt grey--text text--darken-2 white my-auto"},[_vm._v("\n          "+_vm._s(_vm.formatDate(content.publishedAt))+"\n        ")]),_vm._v(" "),(
            content.metadatas.audios && content.metadatas.audios.length != 0
          )?_c('div',{staticClass:"contentMedatas-duration grey--text text--darken-2 d-flex"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-timer-outline")]),_vm._v(" "),_c('p',{staticClass:"my-auto"},[_vm._v("\n            "+_vm._s(_vm.formatTime(
                content.metadatas.audios.reduce(
                  (sum, audio) => sum + audio.metadatas.duration,
                  0
                )
              ))+"\n          ")])],1):(
            content.metadatas.videos && content.metadatas.videos.length != 0
          )?_c('div',{staticClass:"caption grey--text d-flex"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-timer-outline")]),_vm._v(" "),_c('p',{staticClass:"my-auto"},[_vm._v("\n            "+_vm._s(_vm.formatTime(content.metadatas.videos[0].metadatas.duration))+"\n          ")])],1):_vm._e()]),_vm._v(" "),(_vm.isOther)?_c('div',{staticClass:"mt-1 mx-3 d-block"},[_c('p',{staticClass:"tag-name white--text text-xs-center"},[_vm._v("\n          "+_vm._s(content.tagName)+"\n        ")])]):_vm._e()],1)}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }